import './colors';
import AdminPlus from './adminplus';

///////////////////////////////////
// Initialize AdminPlus Sidebars //
///////////////////////////////////
AdminPlus.Sidebar.init()

/////////////////////////////////////
// Custom initializers can go here //
/////////////////////////////////////
