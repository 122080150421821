import './bootstrap';
import './checklogin';
import Chart from 'chart.js';
import Vue from 'vue';
import swal from 'sweetalert';
import './vendor/theme'
import { reject } from 'q';
import introJS from 'intro.js';

Vue.component('messages-component', require('./components/Messages.vue').default);

const app = new Vue({
    el: '#app',
});
  
let progress_stats = $('#stats');
if (progress_stats.length) {
    let ctx = document.getElementById("stats").getContext('2d');
    new Chart(ctx, {
        type: 'pie',
        data: {
            labels: ['Avance', 'Total'],
            datasets: [
                {
                    data: [
                        progress_stats.data('progress'),
                        progress_stats.data('total'),
                    ],
                    backgroundColor: [
                        '#FFCD56',
                        '#4BC0C0'
                    ],
                    borderWidth: 1
                }
            ]
        }
    });
}

$("form.async").submit(function(evt){
    evt.preventDefault();
    let form = $(this);

    let data = new FormData();
    
    $('input[type=file]').each(function(){
        data.append($(this).attr('name'), this.files[0] || null);
    });
    
    form.serializeArray().forEach((val) => {
        data.append(val.name, val.value);
    });

    let buttons = form.find('button[type="submit"]');
    buttons.addClass('disabled').attr('disabled','disabled');

    $.ajax({
        url: form.attr('action'),
        data: data,
        type: form.attr('method'),
        cache: false,
        dataType: "json",
        contentType: false,
        processData: false,
        success: (response) => {
            if(response.status){
                if(response.message != '' && response.message.toUpperCase() != 'OK'){
                    swal({
                        title: response.message,
                        text: "",
                        icon: "success",
                    }).then(() => {
                        if(response.url){
                            window.location = response.url
                        }
                    });
                }else if(response.url){
                    window.location = response.url
                }
            }else{
                swal({
                    title: response.message,
                    text: "",
                    icon: "warning",
                });
            }
        }, error: (e) => {
            console.log(e);
            swal({
                title: "Verifica que tengas conexión a internet y que los datos sean correctos.",
                text: "",
                icon: "danger",
            });
        },complete: () => {
            buttons.removeClass('disabled').removeAttr('disabled');
        }
    });
    
});

$("a.async-action").click(function(evt){
    evt.preventDefault();

    let a = $(this);

    $.ajax({
        url: a.attr('href'),
        type: 'GET',
        headers: {          
            Accept: "application/json; charset=utf-8",        
        },
        success: (response) => {
            console.log(response);
            if(response.status){
                if(response.message != '' && response.message.toUpperCase() != 'OK'){
                    swal({
                        title: response.message,
                        text: "",
                        icon: "success",
                    }).then(() => {
                        if(response.url){
                            window.location = response.url
                        }
                    });
                }else if(response.url){
                    window.location = response.url
                }
            }else{
                swal({
                    title: response.message,
                    text: "",
                    icon: "warning",
                });
            }
        }, error: (e) => {
            console.log(e);
            swal({
                title: "Verifica que tengas conexión a internet y que los datos sean correctos.",
                text: "",
                icon: "danger",
            });
        }
    });
    
});

$(".question-btn").click(function(){

    let total = $("#total-answers").text().trim();

    let restant = $("#restant-answers");
    let total_restant = restant.text().trim();

    let correct = $("#correct-answers");
    let total_correct = correct.text().trim();

    let incorrect = $("#incorrect-answers");
    let total_incorrect = incorrect.text().trim();
    //Validation

    let btn = $(this);
    let target = btn.data('target');
    let index = btn.data('question');
    let answer = $(`form#question-${index} input[name='question']:checked`).val();
    if(!answer){
        swal('Elige una opción para continuar.', '', 'warning');
        return;
    }

    let promise = new Promise((resolve) => {
        let form = $(`form#question-${index}`);
        let data = form.serialize();

        $.ajax({
            url: form.attr('action'),
            data: data,
            type: form.attr('method'),
            cache: false,
            headers: {          
                Accept: "application/json; charset=utf-8",        
            },
            success: (response) => {
                if(response.status){
                    resolve(response.data);
                }else {
                    swal('', response.message, 'warning');
                    reject('');
                }
            }, error: (e) => {
                console.log(e);
                swal({
                    title: "Verifica que tengas conexión a internet y que los datos sean correctos.",
                    text: "",
                    icon: "danger",
                });
                reject('');
            }
        });

        
    });
    promise.then(data => {
        
        if(data.correct){
            total_correct ++;
            correct.text(total_correct);
        }else {
            total_incorrect ++;
            incorrect.text(total_incorrect);
        }

        total_restant --;
        restant.text(total_restant);

        let questions = $('.question-exam');
        questions.addClass('d-none');
        $(`#${target}`).removeClass('d-none');
    }).catch(data => {
        console.log(data);
    });
    
});

$(".end-exam").click(function(){

    let btn = $(this);
    let index = btn.data('question');
    let answer = $(`form#question-${index} input[name='question']:checked`).val();
    if(!answer){
        swal('Elige una opción para continuar.', '', 'warning');
        return;
    }


    let form = $(`form#question-${index}`);

    $.ajax({
        url: form.attr('action'),
        data: form.serialize(),
        type: form.attr('method'),
        cache: false,
        headers: {          
            Accept: "application/json; charset=utf-8",        
        },
        success: (response) => {
            if(response.status){
                window.location = response.url;
            }else {
                swal('', response.message, 'warning');
            }
        }, error: (e) => {
            console.log(e);
            swal({
                title: "Verifica que tengas conexión a internet y que los datos sean correctos.",
                text: "",
                icon: "danger",
            });
        }
    });
});

function setInputFilter(textbox, inputFilter) {
    ["input", "keydown", "keyup", "mousedown", "mouseup", "select", "contextmenu", "drop"].forEach(function(event) {
      textbox.addEventListener(event, function() {
        if (inputFilter(this.value)) {
          this.oldValue = this.value;
          this.oldSelectionStart = this.selectionStart;
          this.oldSelectionEnd = this.selectionEnd;
        } else if (this.hasOwnProperty("oldValue")) {
          this.value = this.oldValue;
          this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
        } else {
          this.value = "";
        }
      });
    });
}

if(document.getElementById("profile-phone")){
    setInputFilter(document.getElementById("profile-phone"), function(value) {
        return /^-?\d*$/.test(value); });

}

$(".pdf-modal").click(function(){

    let url = $(this).data('url');
    PDFObject.embed(url, "#pdf-container");
    $("#pdf-modal").modal('show');
});

function setCookie(cname, cvalue, exdays) {
    let d = new Date();
    exdays = exdays || 365;   
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    let expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

function getCookie(cname) {
    let name = cname + "=";
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for(let c of ca) {
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function showTutorial(ignoreCookie){
    ignoreCookie = ignoreCookie || false;
    let user_id = document.body.getAttribute('data-user');
    if(!isNaN(user_id)){

        if(ignoreCookie){
            introJS().start();
        }else {
            if(window.location.pathname == '/' && getCookie('tutorial-home') != user_id){
                introJS().start().onbeforeexit(function() {
                    setCookie('tutorial-home', user_id)
                });
            } else if(window.location.pathname.indexOf('/courses/') >= 0 && getCookie('tutorial-courses') != user_id){
                introJS().start().onbeforeexit(function() {
                    setCookie('tutorial-courses', user_id)
                });
            }
        }
        
    }
}

$(".help-button").click((evt) => {
    evt.preventDefault();
    showTutorial(true);
});

$(".password-toggler button").click(function(){
    let btn = $(this);
    let icon = btn.parents('.password-toggler').find('i.material-icons');
    let input = btn.parents('.password-toggler').find('input');
    let isVisible = icon.text() != 'visibility';

    if(isVisible){
        input.attr('type', 'password');
        icon.text('visibility');
    }else {
        input.attr('type', 'text');
        icon.text('visibility_off');
    }
})

$(document).ready(function(){

    let categories = $('#profile-categories');
    
    if(categories.length > 0){
        categories.tagsInput({
            autocomplete_url: categories.data('url'),
            defaultText:'Selecciona las categorías de tu interés.',
        });


        $(".tag-custom").click(function(){
            let tag = $(this).text();
            if(categories.val().split(',').indexOf(tag) == -1){
                categories.addTag(tag);
            }
        })
    }

    let feedback_modal = $("#feedback-modal");

    if(feedback_modal.length > 0){
        feedback_modal.modal('show');
    }


    let profile_address = $("#profile-address");

    if(profile_address.length > 0){
        let input = document.getElementById('profile-address');
        let autocomplete = new google.maps.places.Autocomplete(input);

        google.maps.event.addListener(autocomplete, 'place_changed', function() {
            let location = autocomplete.getPlace().geometry.location;
            for(let component of autocomplete.getPlace().address_components){
                if(component.types.indexOf('administrative_area_level_1') >= 0){
                        document.getElementById('profile-state').value = component.long_name;
                    }
            }
            let lat_lng = document.getElementById('profile-lat_lng');
            lat_lng.value = `${location.lat()}, ${location.lng()}`;
        });
          
    }

    let timer = $("#restant-minutes");

    if(timer.length > 0){
        var seconds = Number(timer.data('initial')) * 60;
        var now = (new Date()).getTime();
        var timer_i = setInterval(() => {
            let restant_time = seconds - (((new Date()).getTime() - now) / 1000).toFixed(0);

            if(restant_time >= 0){
                let m = Math.floor(restant_time / 60);
                let s = (restant_time % 60).toFixed(0);
                timer.text(`${m.toString().padStart(2,'0')}:${s.toString().padStart(2,'0')}`);
            }else {
                clearInterval(timer_i);
                window.location = timer.data('cancel-url')
            }
        }, 500);
    }

    let cancel_page = $(".no-refresh-page");
    if(cancel_page.length > 0){
        let url = cancel_page.data('cancel-url');

        $(window).on("beforeunload", function() { 
            window.location = url;
        });

        $(window).blur(function() { 
            window.location = url;
        });

    }

});
