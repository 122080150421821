<template></template>

<script>
    export default {
        props: [
            'message_success',
            'message_warning'
        ],
        mounted() {
            let message;
            let type;
            if(this.message_success !== ''){
                message = this.message_success;
                type = 'success';
            } else if(this.message_warning !== ''){
                message = this.message_warning;
                type = 'warning';
            } 

            if(message && type && message.toUpperCase() != 'OK'){
                swal({
                    title: message,
                    text: "",
                    icon: type
                })
            }
        }
    }
</script>
