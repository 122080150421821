let is_login_page = document.body.classList.contains('login');

if(!is_login_page){
    const seconds = 10;
    let url = document.body.getAttribute('data-url');
    setInterval(() => {
        $.ajax({
            url: url,
            dataType: "json",
            contentType: false,
            processData: false,
            success: (response) => {
                if(response.status){
                    if(response.message != '' && response.message.toUpperCase() != 'OK'){
                        swal({
                            title: response.message,
                            text: "",
                            icon: "success",
                        }).then(() => {
                            if(response.url){
                                window.location = response.url
                            }
                        });
                    }else if(response.url){
                        window.location = response.url
                    }
                }else{
                    swal({
                        title: response.message,
                        text: "",
                        icon: "warning",
                    }).then(() => {
                        if(response.url){
                            window.location = response.url
                        }
                    });
                }
            }, error: (e) => {
                console.log(e);
            }
        });
    }, 1000 * seconds);
}